.app-main-container {
  max-width: 500px;
}

.header-container {
  background: #DCF7FF;
  text-align: center;
}

.header-img {
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.establishment-container {
  text-align: center;
  margin-top: 20px;
  max-width: 600px !important;
  padding-left: 20px;
  padding-right: 20px;
}

.establishment-img-container {}

.establishment-img {
  height: 90px;
  width: 90px;
  border-radius: 100px;
  border: 3px solid #00B2D2;
}

.establishment-name {
  font-style: normal;
  font-weight: 790;
  font-size: 20px;
  line-height: 24px;
  color: #535353;
  margin-top: 5px;
}

.establishment-address {
  font-style: normal;
  font-weight: 457;
  font-size: 14px;
  line-height: 17px;
  color: #909090;
}

.billing-container {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 600px !important;
  padding-left: 20px;
  padding-right: 20px;
}

.billing-start {
  font-style: normal;
  font-weight: 790;
  font-size: 20px;
  line-height: 24px;
  color: #00B2D2;
}

.billing-label {
  font-style: normal;
  font-weight: 656;
  font-size: 16px;
  line-height: 19px;
  color: #686868;
  margin-bottom: 10px;
}

.info-span {
  font-style: normal;
  font-weight: 457;
  font-size: 12px;
  line-height: 14px;
  color: #909090;
}

.info-image {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.billing-data {
  font-style: normal;
  font-weight: 790;
  font-size: 20px;
  line-height: 24px;
  color: #00B2D2;
  margin-top: 20px;
}

.send-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 600px !important;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-emit-bill {
  background: #00B2D2 !important;
  border-radius: 8px !important;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 50px;
  padding-right: 50px;
  font-style: normal;
  font-weight: 790;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}

.float-icon-form {
  position: relative;
}

.float-icon {
  position: absolute;
  top: 9px;
  left: 10px;
  height: 20px;
}

.form-float {
  padding-left: 50px !important;
}

.form-float-2 {
  padding-left: 40px !important;
}



.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.form-group {
  margin-bottom: 10px;
}